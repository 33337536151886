// src/pages/Portfolio.js
import React, { useCallback } from 'react';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import particlesConfig from '../data/particles-config.json';
import './Portfolio.css';

function Portfolio() {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  return (
    <div className="portfolio-container">
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={particlesConfig}
      />
      <div className="rounded-box">
        <h1 style={{ color: '#fff' }}>Coming Soon</h1>
        <p style={{ color: '#fff' }}>
          Stay tuned for updates on my portfolio! Exciting projects and more will be showcased here.
        </p>
        {/* ... (add more content as needed) */}
      </div>
    </div>
  );
}

export default Portfolio;
