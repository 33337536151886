import React from 'react';
import './Contact.css';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import particlesConfig from '../data/particles-config.json';

function Contact() {
  const particlesInit = async (engine) => {
    await loadFull(engine);
  };

  const particlesLoaded = async (container) => {
    await console.log(container);
  };

  const handleSendEmail = () => {
    const email = 'tom@codebytom.co.uk';
    window.location.href = `mailto:${email}?subject=Contact%20Form`;
  };

  return (
    <div className="contact-container" style={{ color: 'white' }}>
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={particlesConfig}
      />
      <div className="rounded-box">
        <h1>Contact Me</h1>
        <p style={{ color: 'white' }}>
          I'm always happy to meet new people! Please feel free to get in
          touch using the button below.
        </p>
        <button onClick={handleSendEmail} className="contact-button">
          Send Email
        </button>
      </div>
    </div>
  );
}

export default Contact;
