// src/pages/About.js
import React, { useCallback } from 'react';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import particlesConfig from '../data/particles-config.json';
import './About.css';

function About() {
  const particlesInit = useCallback(async (engine) => {
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  return (
    <div className="about-container">
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={particlesConfig}
      />
      <div className="rounded-box">
        <h1 style={{ color: '#fff' }}>Greetings, I'm Tom</h1>
        <p style={{ color: '#fff' }}>
          I'm a seasoned Software Engineer from Ireland. My professional journey in the realm of software development has been both dynamic and enriching.
        </p>
        <p style={{ color: '#fff' }}>
          With a comprehensive skill set encompassing JavaScript, C#, Python, TypeScript, HTML, CSS, and more, I've successfully navigated the intricate landscape of modern programming languages.
        </p>
        <p style={{ color: '#fff' }}>
          In the domain of JavaScript, I've demonstrated proficiency in creating sophisticated web applications, ensuring seamless functionality across diverse browsers. C# has been a cornerstone in my toolkit, enabling me to engineer scalable and efficient solutions. Python, with its versatility and power, has served as my language of choice for a spectrum of projects. TypeScript, with its emphasis on static typing for JavaScript, has further elevated the quality and reliability of my code.
        </p>
        <p style={{ color: '#fff' }}>
          HTML and CSS form the foundation of my ability to shape digital interfaces, transforming lines of code into visually compelling and user-friendly experiences.
        </p>
        <p style={{ color: '#fff' }}>
          Beyond the proficiency in programming languages, my focus lies in problem-solving and innovation. From unraveling complex algorithms to designing intuitive user interfaces, I derive immense satisfaction from delivering solutions that transcend expectations.
        </p>
        <p style={{ color: '#fff' }}>
          As I embark on the next chapter of my professional journey, I look forward to leveraging my skills and experience to tackle new challenges and contribute meaningfully to the ever-evolving landscape of software engineering.
        </p>
        {/* ... (add more content as needed) */}
      </div>
    </div>
  );
}

export default About;
